import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { URL_HOME_B, URL_HOME_C } from "@middleware/constants";

import { useRouter } from "next/router";
import HeadHomeB from "./TestB";
import HeadHomeC from "./TestC";
import MainHead from "./MainHead";

export type HomeHeadProps = SliceComponentProps<Content.HomeHeadSlice>;

const HomeHead = ({ slice }: HomeHeadProps): JSX.Element => {
  const router = useRouter();

  if (router.pathname === URL_HOME_B) {
    return <HeadHomeB slice={slice} />;
  } else if (router.pathname === URL_HOME_C) {
    return <HeadHomeC slice={slice} />;
  }

  return <MainHead slice={slice} />;
};

export default HomeHead;
