import React, { useEffect, useState } from "react";
import cn from "clsx";
import { Content } from "@prismicio/client";
import { Link } from "@components/ui";
import { URL_PAGE_MEN, URL_PAGE_WOMEN } from "@middleware/constants";
import { useTranslation } from "next-i18next";
import { PrismicRichText } from "@prismicio/react";
import style from "./HomeHead.module.scss";
import SimpleGellery from "./SimpleGellery";
const Head = ({ slice }: { slice: Content.HomeHeadSlice }): JSX.Element => {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(1);

  useEffect(() => {
    const classes = Array.from({ length: 3 }, (_, i) => i + 1);
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % classes.length;
      setActiveSlide(classes[index]);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section>
      <div className={cn("head", style.head, style["head-" + activeSlide])}>
        <div className={style.feature}>
          <SimpleGellery />
        </div>
        <div className={cn("lk-wrap", style.wrap)}>
          <div className={style.content}>
            <div className={style.title}>
              <PrismicRichText field={slice.primary.title} />
            </div>
            <div className={style.subtitle}>
              <PrismicRichText field={slice.primary.subtitle} />
            </div>
            <div className={style.description}>
              <PrismicRichText field={slice.primary.description} />
            </div>
            <div className={style.action}>
              <Link href={URL_PAGE_WOMEN} className="primary">
                {t("buttons.woman")}
              </Link>
              <Link href={URL_PAGE_MEN} className="primary">
                {t("buttons.man")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Head;
