import React from "react";
import cn from "clsx";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { Content } from "@prismicio/client";
import { useTranslation } from "next-i18next";
import { URL_PAGE_MEN, URL_PAGE_WOMEN } from "@middleware/constants";
import { Link } from "@components/ui";
import { useWindow } from "@middleware/hooks";

import bgImage from "@static/images/bg/bg-head-home.jpg";
import style from "./HomeHead.module.scss";

export default function MainHead({ slice }: { slice: Content.HomeHeadSlice }) {
  const { isDesktop } = useWindow();
  const { t } = useTranslation();
  const bgImageStyle = {
    backgroundImage: isDesktop
      ? `url(${slice.primary.backgroundImage.url as string})`
      : "none",
  };

  return (
    <section>
      <div className={cn("head", style.head)}>
        <div className={style.bg}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt=""
            width="99999"
            height="99999"
            src={bgImage.src}
            // @ts-ignore
            fetchpriority="high"
          />
        </div>
        <div
          className={cn(style.feature, {
            [style.auto]: slice.primary.autoHeight,
          })}
          style={bgImageStyle}
        >
          <PrismicImage
            field={slice.primary.featureDesktop}
            className={style.desktop}
          />
          <PrismicImage
            field={slice.primary.featureMobile}
            className={style.mobile}
          />
        </div>
        <div className={cn("lk-wrap", style.wrap)}>
          <div className={style.content}>
            <div className={style.title}>
              <PrismicRichText field={slice.primary.title} />
            </div>
            <div className={style.subtitle}>
              <PrismicRichText field={slice.primary.subtitle} />
            </div>
            <div className={style.description}>
              <PrismicRichText field={slice.primary.description} />
            </div>
            <div className={style.action}>
              <Link href={URL_PAGE_WOMEN} className="primary">
                {t("buttons.woman")}
              </Link>
              <Link href={URL_PAGE_MEN} className="primary">
                {t("buttons.man")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
