import React from "react";
import cn from "clsx";
import Image from "next/image";
import { Content } from "@prismicio/client";
import { Link } from "@components/ui";
import { URL_PAGE_MEN, URL_PAGE_WOMEN } from "@middleware/constants";
import { useTranslation } from "next-i18next";
import { PrismicRichText } from "@prismicio/react";
import Feature from "@static/images/feature/feature-home-b.png";
import style from "./HomeHead.module.scss";

const Head = ({ slice }: { slice: Content.HomeHeadSlice }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={cn("head", style.head)}>
        <div className={style.feature}>
          <Image
            className={style.image}
            alt="feature top"
            src={Feature}
            quality={100}
            priority
          />
        </div>
        <div className={cn("lk-wrap", style.wrap)}>
          <div className={style.content}>
            <div className={style.title}>
              <PrismicRichText field={slice.primary.title} />
            </div>
            <div className={style.subtitle}>
              <PrismicRichText field={slice.primary.subtitle} />
            </div>
            <div className={style.description}>
              <PrismicRichText field={slice.primary.description} />
            </div>
            <div className={style.action}>
              <Link href={URL_PAGE_WOMEN} className="primary">
                {t("buttons.woman")}
              </Link>
              <Link href={URL_PAGE_MEN} className="primary">
                {t("buttons.man")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Head;
